import React, { FC } from "react";

import { FlatButton } from "../ui";
import { IconName } from "../ui/icons/icons.type";

type ButtonList = {
  icon: IconName;
  label: string;
  link: string;
};

const ConnectWithUs: FC = () => {
  const buttonList: ButtonList[] = [
    {
      icon: "Telegram",
      label: "Telegram",
      link: "https://t.me/skypark_sochi",
    },
    {
      icon: "Vk",
      label: "VK",
      link: "https://vk.com/skyparksochi",
    },
    {
      icon: "Tripadvisor",
      label: "TripAdvisor",
      link: "https://www.tripadvisor.ru/Attraction_Review-g2641470-d6934793-Reviews-Skypark_AJ_Hackett_Sochi-Adler_District_Sochi_Greater_Sochi_Krasnodar_Krai_South.html",
    },
    {
      icon: "Youtube",
      label: "Youtube",
      link: "https://www.youtube.com/channel/UCT8x7x8xTmyHB_l-QusmONA/featured",
    },
    {
      icon: "WhatsUp",
      label: "8 (800) 100-42-07",
      link: "tel:88001004207",
    },
  ];

  const renderIconList = buttonList.map((item, index) => (
    <a key={index} href={item.link} target="_blank" rel="noreferrer">
      <FlatButton icon={item.icon} title={item.label} />
    </a>
  ));

  return (
    <div className="mt-[35px] mb-[90px]">
      <div className="w-full text-center mb-[14px]">
        <h1>Оставайтесь с нами на связи:</h1>
      </div>
      <div className="flex flex-wrap gap-[30px] justify-center">
        {renderIconList}
      </div>
    </div>
  );
};

export default ConnectWithUs;
