import React from "react";

import logo from "../../../resources/images/v16_112.png";

const HeaderLogo = () => {
  return (
    <div>
      <img className="w-full max-w-[314.34px]" src={logo} alt="" />
    </div>
  );
};

export default HeaderLogo;
