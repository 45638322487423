import React, { FC } from "react";

import { Player } from "../ui";
import useFileTypeQualifier from "../../hooks/use-file-type-qualifier";

import { boxPreviewClassName } from "./file-preview.styles";

type BoxPreviewProps = {
  file: string;
};

const BoxPreview: FC<BoxPreviewProps> = (props) => {
  const { file } = props;
  const qualifier = useFileTypeQualifier();
  const renderFileView = () => {
    const fileType = [...file.split(".")].pop();

    if (qualifier.isVideo(fileType)) {
      return <Player file={file} />;
    } else {
      return (
        <div className="flex items-center justify-center">
          <img src={file} className="object-contain  h-full" alt="" />
        </div>
      );
    }
  };

  return <div className={boxPreviewClassName}>{renderFileView()}</div>;
};

export default BoxPreview;
