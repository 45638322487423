import React, { FC } from "react";

import { Item } from "../../models/item.type";
import noPreviewImage from "../../resources/images/noPreview.png";

import useFileTypeQualifier from "../../hooks/use-file-type-qualifier";

import {
  filePreviewClassName,
  filePreviewImageClassName,
} from "./file-preview.styles";

type FileProps = {
  file: Item;
  onClick: (Item) => void;
  isSelect?: boolean;
};

const File: FC<FileProps> = (props) => {
  const { file, onClick, isSelect } = props;
  const qualifier = useFileTypeQualifier();
  const handleFileSelect = () => {
    onClick?.(file);
  };
  const changeImageIfExists = (event) => {
    event.target.src = noPreviewImage;
  };

  return (
    <div onClick={handleFileSelect} className={filePreviewClassName(isSelect)}>
      <img
        onError={changeImageIfExists}
        src={qualifier.isVideo(file.file_link) ? file.preview : file.file_link}
        className={filePreviewImageClassName}
        alt=""
      />
    </div>
  );
};

export default File;
